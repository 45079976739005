import { format, isSameDay, isSameMonth } from 'date-fns';
import React, { FC, useMemo, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import useSSRLayoutEffect from '../../../hooks/useSSRLayoutEffect';
import { CalendarDay } from '../types';
import CalendarGridCellLabel from './CalendarGridCellLabel';

interface CalendarGridCellProps {
  day: CalendarDay;
  selectedDay: Date;
  selectedMonth: Date;
  selectDay: React.Dispatch<React.SetStateAction<Date>>;
}

const CalendarGridCell: FC<CalendarGridCellProps> = ({ day, selectedDay, selectedMonth, selectDay }) => {
  // Determine day context
  const sameMonth = useMemo(() => isSameMonth(day.date, selectedMonth), [day.date, selectedMonth]);
  const sameDay = useMemo(() => isSameDay(day.date, selectedDay), [day.date, selectedDay]);

  const isMobile = useMediaQuery({
    query: '(max-device-width: 768px)',
  });
  // Update classes from context
  const ref = useRef<HTMLDivElement>(null);
  useSSRLayoutEffect(() => ref.current?.classList[!sameMonth ? 'add' : 'remove']('disabled'), [sameMonth]);
  useSSRLayoutEffect(() => ref.current?.classList[sameDay ? 'add' : 'remove']('selected'), [sameDay]);

  const formattedDate = useMemo(() => format(day.date, 'd'), [day.date]);

  return (
    <div ref={ref} className='col cell' onClick={() => sameDay || selectDay(day.date)}>
      <span className='number'>{formattedDate}</span>
      <div className='labels'>
        {/* Display one label per events */}
        {day.events.map((evt, idx) => {
          const length = day.events.length;
          if (idx < 2) {
            return <CalendarGridCellLabel key={`event-label-${evt.id}`} event={evt} date={day} />;
          } else if (idx > 2 && idx < 4) {
            if (isMobile) {
              return (
                <div key={`event-more-mobile-${evt.id}`} style={{ color: 'white' }}>
                  +
                </div>
              );
            } else {
              return (
                <div key={`event-more-${evt.id}`} style={{ color: '#d13639' }}>
                  + {length - 1} more
                </div>
              );
            }
          }
        })}
      </div>
    </div>
  );
};

export default CalendarGridCell;
