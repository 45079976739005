import React from 'react';

const CalendarFilters: React.FC = () => {
  return (
    <div className='calendar-filters'>
      {/* <Button
        onClick={() => {
          console.log('clicked');
        }}
        variant='contained'
        endIcon={<ClearIcon />}
      >
        LoL
      </Button> */}
    </div>
  );
};

export default CalendarFilters;
