import { format } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import calendarLocalesMap from './langs';
import { CalendarDay, GridData } from './types';

type MobileEventPreviewProps = {
  selectedDay: Date;
  gridData: GridData;
  lang: string;
};

const MobileEventPreview: FC<MobileEventPreviewProps> = ({ selectedDay, gridData, lang }) => {
  const [selectedDayEvent, setSelectedDayEvent] = useState<CalendarDay>();

  const gameColors = {
    valorant: '#FF4655',
    lol: '#0BC6E3',
    wild_rift: '#2858F0',
    tft: '#6A55C4',
    lor: '#DF9036',
  };

  useEffect(() => {
    gridData.forEach(week => {
      week.forEach(day => {
        if (day.date.getTime() === selectedDay.getTime()) {
          setSelectedDayEvent(day);
        }
      });
    });
  }, [selectedDay]);

  return (
    <div id='events' className='pt-20 lg:pt-32'>
      <h3 className='md:text-xl font-MarkSCPro mb-3'>
        {format(selectedDay, 'EEEE dd MMM', { locale: calendarLocalesMap[(lang || 'en') as keyof typeof calendarLocalesMap] })}
      </h3>
      <div className='pl-3 divide-y divide-white'>
        {selectedDayEvent?.events.map(event => (
          <div key={event.id} className='relative py-3'>
            <span
              className='absolute -left-3 h-2/3 top-1/2 transform-gpu -translate-y-1/2 w-1 rounded-sm'
              style={{ backgroundColor: gameColors[`${event.games[0]}`] }}
            />
            <p>{event.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileEventPreview;
