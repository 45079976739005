import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { addMonths, subMonths } from 'date-fns';
import React, { FC, useCallback } from 'react';
import calendarLocalesMap from './langs';

interface CalendarHeaderProps {
  lang?: string;
  selectedMonth: Date;
  setSelectedMonth: React.Dispatch<React.SetStateAction<Date>>;
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const arrowSvg = require('../../assets/icon/calendar/bold-arrow.svg').default;
const paramSvg = require('../../assets/icon/calendar/parameters.svg').default;

const CalendarHeader: FC<CalendarHeaderProps> = ({ lang, selectedMonth, setSelectedMonth, setMenuIsOpen }) => {
  const prevMonth = useCallback(() => setSelectedMonth(d => subMonths(d, 1)), [setSelectedMonth]);
  const nextMonth = useCallback(() => setSelectedMonth(d => addMonths(d, 1)), [setSelectedMonth]);

  return (
    <div className='calendar-header'>
      <div className='calendar-left'>
        <div className='calendar-month-selector' onClick={prevMonth}>
          <img src={arrowSvg} alt='go to previous month' />
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={calendarLocalesMap[(lang || 'en') as keyof typeof calendarLocalesMap]}>
          <DatePicker
            variant='inline'
            views={['year', 'month']}
            openTo='month'
            value={selectedMonth}
            onChange={e => setSelectedMonth(e as unknown as Date)}
          />
        </MuiPickersUtilsProvider>
        <div className='calendar-month-selector' onClick={nextMonth}>
          <img src={arrowSvg} alt='go to next month' />
        </div>
      </div>
      <div className='calendar-right'>
        <div className='calendar-parameters' onClick={() => setMenuIsOpen(true)}>
          <img src={paramSvg} alt='filter parameters logo' />
        </div>
      </div>
    </div>
  );
};

export default CalendarHeader;
