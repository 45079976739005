import React, { FC } from 'react';
import UiElements from '../../../interfaces/ui-elements.interface';
import { CalendarFiltersType } from '../types';
import CalendarGameInput from './CalendarGameInput';
import CalendarRegionInput from './CalendarRegionInput';

interface CalendarFormProps {
  filters: CalendarFiltersType;
  setFilters: any;
  uiElements: UiElements;
}

const CalendarForm: FC<CalendarFormProps> = ({ filters, setFilters, uiElements }) => {
  const setFilter = (key: keyof CalendarFiltersType) => (x: unknown[]) =>
    setFilters((xs: CalendarFiltersType) => ({ ...xs, [key]: x || [] }));
  return (
    <div className='calendar-forms'>
      <CalendarGameInput setGames={setFilter('games')} games={filters.games} title={uiElements.calendarTxt[0].games} />
      <CalendarRegionInput
        selectRegions={setFilter('regions')}
        regions={filters.regions}
        title={uiElements.calendarTxt[0].regions}
        regionsTranslations={
          uiElements.regionsTxt || [
            {
              code: 'turkey',
              region: 'Turkey',
            },
            {
              code: 'ibit',
              region: 'Iberia & Italia',
            },
            {
              code: 'frb',
              region: 'France & Benelux',
            },
            {
              code: 'uk',
              region: 'Uk & Nordics',
            },
            {
              code: 'central_europe',
              region: 'Central Europe',
            },
            {
              code: 'eastern_europe',
              region: 'Eastern Europe',
            },
            {
              code: 'cis',
              region: 'Russia & CIS',
            },
            {
              code: 'mena',
              region: 'Middle East & North Africa',
            },
          ]
        }
      />
    </div>
  );
};

export default CalendarForm;
