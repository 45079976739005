import { InputLabel, ListItemIcon, ListItemText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import ArrowSvg from '../../../assets/icon/calendar/arrow.svg';
import { RegionsTxt } from '../../../interfaces/ui-elements.interface';
import { AvailableRegions } from '../types';

const StyledFormControl = withStyles({
  root: {
    minWidth: '120px',
    border: '1px solid',
    marginLeft: '30px',
  },
})(FormControl);

interface CalendarRegionInputProps {
  selectRegions(xs: AvailableRegions[]): void;
  regions: AvailableRegions[];
  title: string;
  regionsTranslations: RegionsTxt[];
}

const CalendarRegionInput: FC<CalendarRegionInputProps> = ({ selectRegions, regions, title, regionsTranslations }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 768px)',
  });

  const regionMap = new Map(regionsTranslations.map(region => [region.code, region.region]));

  if (!isMobile) {
    return (
      <StyledFormControl>
        <InputLabel id='demo-simple-select-label'>{title}</InputLabel>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          multiple
          value={regions}
          className='select-calendar-label'
          IconComponent={ArrowSvg}
          style={{ height: '100%', position: 'unset' }}
          onChange={e => selectRegions(e.target.value as AvailableRegions[])}
        >
          {Array.from(regionMap.keys()).map((r, index) => (
            <MenuItem key={`calendar-select-games-${index}`} value={r}>
              <Checkbox checked={regions.includes(r)} inputProps={{ 'aria-label': 'primary checkbox' }} />
              {regionMap.get(r)}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    );
  } else {
    return (
      <List>
        <h3>{title}</h3>
        {Object.values(regions).map((c, index) => (
          <ListItem key={`calendar-select-games-${index}`} role={undefined} dense button>
            <ListItemIcon>
              <Checkbox edge='start' tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={`calendar-select-games-${index}`} primary={c} />
          </ListItem>
        ))}
      </List>
    );
  }
};

export default CalendarRegionInput;
