import { Button } from '@material-ui/core';
import React, { FC, useRef } from 'react';
import useSSRLayoutEffect from '../../hooks/useSSRLayoutEffect';

interface CalendarMobileParamMenuProps {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  forms?: React.ReactNode;
  filters?: React.ReactNode;
}

const CalendarMobileParamMenu: FC<CalendarMobileParamMenuProps> = ({ forms, isOpen, setIsOpen, filters }) => {
  const ref = useRef<HTMLDivElement>(null);

  useSSRLayoutEffect(() => {
    if (!ref.current) return;
    ref.current.classList[isOpen ? 'add' : 'remove']('is-open');
  }, [isOpen, ref.current]);

  return (
    <div ref={ref} className='param-menu h-screen w-screen'>
      <button onClick={() => setIsOpen(false)} className='param-menu-cross'>
        <span className='line'></span>
        <span className='line'></span>
      </button>
      <h3>Filters</h3>
      <div className='filters'>{filters}</div>
      {forms}
      <div className='button-container'>
        <Button variant='contained'>/Apply</Button>
      </div>
    </div>
  );
};

export default CalendarMobileParamMenu;
