import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { isClient } from '../../../utils';
import calendarLocalesMap from '../langs';
import { GridData } from '../types';
import CalendarGridCell from './CalendarGridCell';

interface CalendarGridProps {
  lang: string;
  gridData: GridData;
  selectedDay: Date;
  selectedMonth: Date;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
}

const CalendarGrid: FC<CalendarGridProps> = ({ gridData, selectedDay, selectedMonth, setSelectedDay, lang }) => {
  // Define day label style (short or long)

  const dayLabelFormat = useMemo(() => {
    return isClient ? (window.innerWidth > 768 ? 'EEEE' : 'EEEEE') : 'EEEEE';
  }, []);

  return (
    <>
      {/* Day labels */}
      <div className='grid grid-cols-7 mb-4'>
        {gridData.length > 0 &&
          gridData[0].map((day, i) => (
            <span key={`day-label-${i}`} className='text-sm md:text-lg text-center'>
              {format(day.date, dayLabelFormat, { locale: calendarLocalesMap[(lang || 'en') as keyof typeof calendarLocalesMap] })}
            </span>
          ))}
      </div>

      {/* Cells */}
      <div className='calendar-body'>
        {gridData.map((week, i) => (
          <div key={`calendar-row-${i}`} className='row'>
            {week.map((day, j) => (
              <CalendarGridCell
                key={`calendar-cell-${i}-${j}`}
                day={day}
                selectedDay={selectedDay}
                selectedMonth={selectedMonth}
                selectDay={setSelectedDay}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default CalendarGrid;
