import { ListItemIcon, ListItemText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import ArrowSvg from '../../../assets/icon/calendar/arrow.svg';
import { GAMES, GameType } from '../../../constants/games';

const StyledFormControl = withStyles({
  root: {
    minWidth: '120px',
    border: '1px solid',
  },
})(FormControl);

interface CalendarGameInputProps {
  setGames(games: GameType[]): void;
  games: GameType[];
  title: string;
}

const ValorantCheckbox = withStyles({
  root: {
    color: 'rgb(255, 70, 85)',
    '&$checked': {
      color: 'rgb(255, 70, 85)',
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />);

const LOLCheckbox = withStyles({
  root: {
    color: 'rgb(11, 198, 227)',
    '&$checked': {
      color: 'rgb(11, 198, 227)',
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />);

const TFTCheckbox = withStyles({
  root: {
    color: 'rgb(255, 183, 67)',
    '&$checked': {
      color: 'rgb(255, 183, 67)',
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />);

const LORCheckbox = withStyles({
  root: {
    color: 'rgb(213, 147, 74)',
    '&$checked': {
      color: 'rgb(213, 147, 74)',
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />);

const WRCheckbox = withStyles({
  root: {
    color: 'rgb(40, 88, 240)',
    '&$checked': {
      color: 'rgb(40, 88, 240)',
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />);

const CalendarGameInput: FC<CalendarGameInputProps> = ({ setGames, games, title }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 768px)',
  });
  if (!isMobile) {
    return (
      <StyledFormControl>
        <InputLabel id='demo-simple-select-label'>{title}</InputLabel>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          multiple
          IconComponent={ArrowSvg}
          id='select-label'
          className='select-calendar-label'
          value={games}
          style={{ height: '100%', position: 'unset' }}
          onChange={e => setGames(e.target.value as GameType[])}
        >
          {Object.values(GAMES).map((g, index) => {
            switch (g.value) {
              case 'lol':
                return (
                  <MenuItem key={`calendar-select-games-${index}`} value={g.value}>
                    <LOLCheckbox checked={games.includes(g.value)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                    {g.displayName}
                  </MenuItem>
                );
              case 'valorant':
                return (
                  <MenuItem key={`calendar-select-games-${index}`} value={g.value}>
                    <ValorantCheckbox checked={games.includes(g.value)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                    {g.displayName}
                  </MenuItem>
                );
              case 'tft':
                return (
                  <MenuItem key={`calendar-select-games-${index}`} value={g.value}>
                    <TFTCheckbox checked={games.includes(g.value)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                    {g.displayName}
                  </MenuItem>
                );
              case 'lor':
                return (
                  <MenuItem key={`calendar-select-games-${index}`} value={g.value}>
                    <LORCheckbox checked={games.includes(g.value)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                    {g.displayName}
                  </MenuItem>
                );
              case 'wild_rift':
                return (
                  <MenuItem key={`calendar-select-games-${index}`} value={g.value}>
                    <WRCheckbox checked={games.includes(g.value)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                    {g.displayName}
                  </MenuItem>
                );
              default:
                return (
                  <MenuItem key={`calendar-select-games-${index}`} value={g.value}>
                    <Checkbox checked={games.includes(g.value)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                    {g.displayName}
                  </MenuItem>
                );
            }
          })}
        </Select>
      </StyledFormControl>
    );
  } else {
    return (
      <List>
        <h3>{title}</h3>
        {Object.values(gamesColor).map((g, index) => (
          <ListItem key={`calendar-select-games-${index}`} role={undefined} dense button>
            <ListItemIcon>
              <Checkbox edge='start' tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={`calendar-select-games-${index}`} primary={g.displayName} />
          </ListItem>
        ))}
      </List>
    );
  }
};

export default CalendarGameInput;
