import {
  addDays,
  areIntervalsOverlapping,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import React, { FC, useMemo, useState } from 'react';
import RTLayout from '../../components/global/layout/layout';
import SEO from '../../components/global/seo/seo';
import UiElements from '../../interfaces/ui-elements.interface';
import * as styles from './calendar.module.scss';
import CalendarFilters from './filters';
import CalendarForm from './form';
import CalendarGrid from './grid';
import CalendarHeader from './Header';
import MobileEventPreview from './mobileEventPreview';
import CalendarMobileParamMenu from './MobileParamMenu';
import { CalendarEvent, CalendarFiltersType, CalendarSubcalendar, GridData } from './types';

/* Minimal filters */
const defaultFilters: CalendarFiltersType = {
  games: [],
  regions: [],
  countries: [], // TODO: narrow possible countries by region
  onOrOffline: 'both',
};

/** Quick filtering comparison util */
function keepEvent<T = unknown>(filters: T[], evtInfo?: T[]): boolean {
  return !filters.length || !evtInfo?.length || evtInfo.some(x => filters.includes(x));
}

type RTCalendarProps = {
  pageContext: {
    lang: string;
    calendarEvents: CalendarEvent[];
    calendarSubcalendars: CalendarSubcalendar[];
    uiElements: UiElements;
  };
};

// Maybe use a 'page' w/ gql static query
const RTCalendar: FC<RTCalendarProps> = ({ pageContext }) => {
  // Param menu can be open/closed
  const [isParamMenuOpen, setIsParamMenuOpen] = useState<boolean>(false);

  /**
   * Events & filtering
   */
  // Separate day selected day from displayed month
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  // Displayed data is a subset of the one previously fetched
  const [filters, setFilters] = useState<CalendarFiltersType>(defaultFilters);

  const filteredEvents = useMemo(() => {
    return pageContext.calendarEvents
      .map(e => ({
        ...e,
        span: {
          start: new Date(e.span.start),
          end: new Date(e.span.end),
        },
      }))
      .filter(e => e !== null)
      .filter(e => keepEvent(filters.games, e.games))
      .filter(e => keepEvent(filters.regions, e.regions));
  }, [pageContext.calendarEvents, filters]);
  // Merge days and events
  const weekStartsOn = useMemo(() => (!pageContext.lang || pageContext.lang === 'en' ? 0 : 1), [pageContext.lang]);
  const monthInterval = useMemo(
    () => ({
      start: startOfWeek(startOfMonth(selectedMonth), { weekStartsOn }),
      end: endOfWeek(endOfMonth(selectedMonth), { weekStartsOn }),
    }),
    [selectedMonth, weekStartsOn],
  );

  const eventsThisMonth = useMemo(
    () => filteredEvents.filter(evt => areIntervalsOverlapping(monthInterval, evt.span)),
    [monthInterval, filteredEvents],
  );

  const gridData: GridData = useMemo(
    () =>
      // Get first day of each week in month
      eachWeekOfInterval(monthInterval, { weekStartsOn })
        // Get all days within week
        .map(fst => Array.from(Array(7), (_, i) => addDays(fst, i)))
        .map(week =>
          week.map(date => ({
            date: new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000),
            events: eventsThisMonth.filter(evt => {
              return isWithinInterval(new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000), evt.span);
            }),
          })),
        ),
    [monthInterval, eventsThisMonth, weekStartsOn],
  );

  return (
    <RTLayout
      menuText={pageContext?.uiElements.menuTxt[0]}
      footerText={pageContext?.uiElements.footerTxt[0]}
      langModalTranslations={{ title: pageContext?.uiElements.languageModalTitle, subtitle: pageContext.uiElements?.languageModalText }}
      cookie={{
        cookieContent: pageContext?.uiElements.cookieContent,
        cookieButtonAccept: pageContext?.uiElements.cookieButtonAccept,
        cookieButtonReject: pageContext?.uiElements.cookieButtonReject,
      }}
    >
      <SEO title='Calendar' />
      <div id='calendar' className={`${styles.wrapper} calendar`}>
        <div className='container'>
          <div className={styles.container}>
            {/* Header */}
            <CalendarMobileParamMenu
              forms={<CalendarForm {...{ filters, setFilters }} uiElements={pageContext.uiElements} />}
              filters={<CalendarFilters />}
              setIsOpen={setIsParamMenuOpen}
              isOpen={isParamMenuOpen}
            />
            <CalendarHeader lang={pageContext.lang || 'en'} setMenuIsOpen={setIsParamMenuOpen} {...{ selectedMonth, setSelectedMonth }} />

            {/* Filtering game/region/country */}
            <CalendarForm {...{ filters, setFilters }} uiElements={pageContext.uiElements} />

            {/* Showing filters */}
            <CalendarFilters />

            {/* Calendar grid */}
            <CalendarGrid {...{ gridData, selectedDay, selectedMonth, setSelectedDay }} lang={pageContext.lang} />
            <MobileEventPreview lang={pageContext.lang} selectedDay={selectedDay} gridData={gridData} />
          </div>
        </div>
      </div>
    </RTLayout>
  );
};

export default RTCalendar;
