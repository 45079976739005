import { Grow, Theme, Tooltip, useMediaQuery, withStyles } from '@material-ui/core';
import { isSameDay } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { GAMES } from '../../../constants/games';
import { CalendarDay, CalendarEvent } from '../types';

interface CalendarGridCellLabelProps {
  event: CalendarEvent;
  date: CalendarDay;
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#232323',
    color: '#fff',
    fontSize: '15px',
    minHeight: '100px',
    minWidth: '170px',
    padding: '30px',
    boxShadow: '0 2px 23px 0 rgba(0,0,0,0.19)',
  },
}))(Tooltip);

const CalendarGridCellLabel: FC<CalendarGridCellLabelProps> = ({ event, date }) => {
  const isMobile = useMediaQuery('(max-width:768px)');

  const [isStart, setIsStart] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    setIsStart(isSameDay(date.date, event.span.start));
    setIsEnd(isSameDay(date.date, event.span.end));
  });

  return (
    <HtmlTooltip
      enterDelay={500}
      title={
        <>
          {event.games.map(game => (
            <div key={`calendar-label-tooltip-${game}`} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <span
                className='circle'
                style={{
                  display: 'block',
                  height: '0.5rem',
                  width: '0.5rem',
                  borderRadius: '3.25rem',
                  position: 'absolute',
                  left: '-1rem',
                  backgroundColor: GAMES.find(obj => obj.value === game)?.border,
                }}
              ></span>
              {GAMES.map(g => {
                if (g.value === game) {
                  return `${g.displayName.charAt(0).toUpperCase() + g.displayName.slice(1)}`;
                }
              })}
            </div>
          ))}
          <div style={{ margin: '0.625rem 0' }}>{event.title}</div>
          <div>{event.countries}</div>
        </>
      }
      arrow
      placement='right'
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 500 }}
    >
      <div
        className={`event ${isStart ? 'start' : isEnd ? 'end' : 'mid'}`}
        style={
          !isMobile
            ? {
                overflow: isStart ? 'visible' : 'hidden',
                backgroundColor: GAMES.find(obj => obj.value === event.games[0])?.bg,
              }
            : {}
        }
      >
        <span
          className='decorator'
          style={{
            display: isStart ? 'block' : 'none',
            backgroundColor: GAMES.find(obj => obj.value === event.games[0])?.border,
          }}
        ></span>
        <span className='text'>{isStart ? event.title : ''}</span>
      </div>
    </HtmlTooltip>
  );
};

export default CalendarGridCellLabel;
